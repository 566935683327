import React from "react";
import ThreadHelper from "../../../../entities/thread/thread-helper";

const Thread = ({
  userImg,
  userInitials,
  userName,
  message,
  date,
  unreadCount,
  threadId,
  isActive,
  thread,
}) => {
  const getInitials = (input) => {
    if (typeof input !== "string" || !input.trim()) {
      return ["U1", "U2"];
    }

    // Split the string using '&' only
    let names = input.split(/\s*&\s*/).map((name) => name.trim());

    // Extract initials
    let initials = names.map((name) => {
      let parts = name.split(/\s+/).filter(Boolean); // Split by space and remove empty parts
      if (parts.length === 1) return parts[0][0].toUpperCase(); // Single name case
      return parts.map((part) => part[0].toUpperCase()).join("");
    });

    return initials.length >= 2 ? initials : ["U1", "U2"];
  };
  const roomInitials = getInitials(userName);
  return (
    <a
      href="#"
      className={`media ${isActive ? "new-message" : ""}`}
      onClick={(e) => {
        e.preventDefault();
        ThreadHelper.makeThreadActive(thread);
      }}
    >
      <div className="media-left ">
        {/* If user image exists, show it, otherwise show initials */}
        {userImg ? (
          // <img src={userImg} alt="user" className="rounded-circle thumb-md" />

          <div className="roomInitials">
            <div className="initial one">{roomInitials[0]}</div>
            <div className="initial two">{roomInitials[1]}</div>
          </div>
        ) : (
          <div className="avatar-box thumb-md align-self-center">
            <span className="thumb-md justify-content-center d-flex align-items-center bg-soft-primary rounded-circle">
              {userInitials}
            </span>
          </div>
        )}
        {thread?.type === "room" &&
          thread.lastMessagePreview.indexOf("left the room") === -1 && (
            <span className="round-10 bg-success" />
          )}
      </div>
      <div className="media-body">
        <div className="d-inline-block">
          <h6>{userName}</h6>
          <p>{message}</p>
        </div>
        <div>
          <span className="time">
            {ThreadHelper.getFormattedDateTime(date)}
          </span>
          {unreadCount > 0 ? <span>{unreadCount}</span> : ""}
        </div>
      </div>
    </a>
  );
};

export default Thread;
