import { default as React } from "react";
import ChatWindow from "../../components/chat-window/chat-window";
import ThreadsListViewer from "../../components/threads-list-viewer/threads-list-viewer";
import "./chat-page.scss";
import NewThreadPopup from "../../components/new-thread-popup/new-thread-popup";
import TwilioSandboxWarningPopup from "../../components/twilio-sandbox-warning-popup/twilio-sandbox-warning-popup";
import BulkRoomCreateModal from "../../../manage-contacts/pages/manage-contacts-page/components/bulk-room-create/bulk-room-create";

const ChatPage = () => {
  return (
    <div className="row chatPageWrapper">
      <div className="col-12 chatPageWrapperInner">
        <div className="chat-box-left">
          <ThreadsListViewer />
        </div>
        <div className="chat-box-right">
          <ChatWindow />
        </div>
      </div>
      <NewThreadPopup />
      <TwilioSandboxWarningPopup />

      <BulkRoomCreateModal />
    </div>
  );
};

export default ChatPage;
