import React from "react";
import PropTypes from "prop-types";
import "./delete-user-confirmation-modal.scss";

const DeleteUserConfirmationModal = ({ show, onClose, onDelete, userName }) => {
  return (
    <div
      className={`modal fade deleteUserConfirmationModal1 ${show ? "show d-block" : ""}`}
      role="dialog"
      aria-labelledby="deleteUserConfirmationModal1"
      aria-hidden={!show}
      style={{ display: show ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header bg-danger">
            <h6 className="modal-title m-0 text-white" id="deleteUserConfirmationModal1">
              Confirm deletion of user
            </h6>
            <button type="button" className="btn-close" onClick={onClose}></button>
          </div>
          <div className="modal-body d-block">
            {/* <h5>You are about to delete this user <b>{userName}</b>!</h5> */}
            <h5>You are about to delete this user!</h5>
            <h5>Are you sure you wish to proceed?</h5>
          </div>
          <div className="modal-footer d-flex align-items-center justify-content-between">
            <button type="button" className="btn btn-de-secondary btn-sm" onClick={onClose}>
              Cancel
            </button>
            <button type="button" className="btn btn-danger btn-sm" onClick={onDelete}>
              Yes, delete this user
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

DeleteUserConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
};

export default DeleteUserConfirmationModal;
