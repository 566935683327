import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Papa from "papaparse";
import ContactHelper from "../../../../entities/contact/contact-helper";
import "./add-contact-modal.scss";

const AddContactModal = ({ show, onHide, onContactAdded }) => {
  const [activeTab, setActiveTab] = useState("single");
  const [contactName, setContactName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [uploadFile, setUploadFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [results, setResults] = useState([]);
  const [viewResults, setViewResults] = useState(false);

  useEffect(() => {
    if (!show) {
      setContactName("");
      setPhoneNumber("");
      setUploadFile(null);
      setActiveTab("single");
      setError(null);
      setResults([]);
      setViewResults(false);
    }
  }, [show]);

  // Utility function to format and validate phone numbers
  const formatPhoneNumber = (phone) => {
    let formatted = phone.replace(/[^\d+]/g, ""); // Remove all characters except digits and '+'

    if (!formatted.startsWith("+")) {
      return { valid: false, phone: formatted, error: "Phone number must include country code (e.g., +1...)" };
    }

    return { valid: true, phone: formatted };
  };

  const handleFileChange = (e) => {
    setUploadFile(e.target.files[0]);
  };

  const handleSingleSubmit = async () => {
    if (!contactName || !phoneNumber) {
      setError("Please fill in both fields.");
      return;
    }

    const formattedPhone = formatPhoneNumber(phoneNumber);
    if (!formattedPhone.valid) {
      setError(formattedPhone.error);
      return;
    }

    setIsLoading(true);
    setError(null);
    try {
      await ContactHelper.createNewContact({
        name: contactName,
        phone: formattedPhone.phone,
      });
      onContactAdded();
      onHide();
    } catch (err) {
      setError(`Error: ${err?.response?.data?.message}` || "Failed to add contact. Please try again.");
    }
    setIsLoading(false);
  };

  const handleBulkSubmit = async () => {
    if (!uploadFile) {
      setError("Please select a CSV file.");
      return;
    }
    setIsLoading(true);
    setError(null);
    setResults([]);

    Papa.parse(uploadFile, {
      complete: async (result) => {
        const dataRows = result.data.slice(1); // Skip first row (headers)

        const contacts = dataRows
          .filter((row) => row.length >= 2)
          .map(([name, phone]) => {
            const formattedPhone = formatPhoneNumber(phone);
            return {
              name,
              phone: formattedPhone.phone,
              valid: formattedPhone.valid,
              error: formattedPhone.valid ? null : formattedPhone.error,
            };
          });

        // Filter out contacts with validation errors
        const invalidContacts = contacts.filter(contact => !contact.valid);

        if (invalidContacts.length > 0) {
          // If there are validation errors, show them and do not call API
          setResults(invalidContacts);
          setViewResults(true);
          setUploadFile(null);
          setIsLoading(false);
          return;
        }

        // If no validation errors, proceed with API calls
        const promises = contacts.map(async (contact) => {
          try {
            await ContactHelper.createNewContact({
              name: contact.name,
              phone: contact.phone,
            });
            return { ...contact, status: "Success" };
          } catch (err) {
            return { ...contact, status: "Failed", error: err?.response?.data?.message || "Unknown error" };
          }
        });

        const responses = await Promise.all(promises);
        onContactAdded();
        setResults(responses);
        setViewResults(true);
        setUploadFile(null);
        setIsLoading(false);
      },
      header: false,
      skipEmptyLines: true,
    });
  };

  const handleDownloadSample = () => {
    const csvContent = "name,phone\nJohn Doe,+1234567890\nJane Doe,+0987654321";
    const blob = new Blob([csvContent], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "sample_contacts.csv";
    link.click();
  };

  useEffect(() => {
    setError(null);
    setResults([]);
    setViewResults(false);
  }, [activeTab]);

  if (!show) return null;

  return (
    <div className="modal-wrapper">
      <div className="modal fade show" style={{ display: "block" }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content add-contact-modal">
            <div className="modal-header">
              <h6 className="modal-title">Add Contact</h6>
              <span type="button" className="" onClick={onHide}><i className="fa fa-times"></i></span>
            </div>

            <div className="modal-body">
              <div className="tab-container">
                <button className={activeTab === "single" ? "active" : ""} onClick={() => setActiveTab("single")}>
                  Add Single Contact
                </button>
                <button className={activeTab === "bulk" ? "active" : ""} onClick={() => setActiveTab("bulk")}>
                  Bulk Upload (CSV)
                </button>
              </div>
            
              {error && <div className="error-message">{error}</div>}
              {viewResults ? (
                <div className="result-table-container">
                  <table className="result-table sleek-table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Error Message</th>
                      </tr>
                    </thead>
                    <tbody>
                      {results.map((result, index) => (
                        <tr key={index} className="error-row">
                          <td>{result.name}</td>
                          <td>{result.phone}</td>
                          <td>{result.error || "-"}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <button className="btn secondary-btn" onClick={() => setViewResults(false)}>
                    Back
                  </button>
                </div>
              ) : activeTab === "single" ? (
                <div className="contact-form">
                  <input type="text" placeholder="Full Name" value={contactName} onChange={(e) => setContactName(e.target.value)} />
                  <input type="text" placeholder="Phone Number with country code (+XXXXXXXXXX)" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                  <button className="btn btn-primary" onClick={handleSingleSubmit} disabled={isLoading}>
                    {isLoading ? "Saving..." : "Save Contact"}
                  </button>
                </div>
              ) : (
                <div className="file-upload">
                  <p>Upload a CSV file to import contacts.</p>
                  <label className="upload-box">
                    <input type="file" accept=".csv" onChange={handleFileChange} />
                    <span>{uploadFile ? uploadFile.name : "Choose File"}</span>
                  </label>
                  <button className="btn" onClick={handleDownloadSample}>
                    Download Sample CSV
                  </button>
                  <button className="btn btn-primary my-2" onClick={handleBulkSubmit} disabled={isLoading}>
                    {isLoading ? "Uploading..." : "Upload CSV"}
                  </button>
                </div>
              )}
            </div>

            {/* <div className="modal-footer">
              <button type="button" className="btn close-btn" >
                Close
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

AddContactModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default AddContactModal;
