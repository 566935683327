import React, { useEffect, useRef } from "react";
import ChatMessage from "../chat-message/chat-message";
import MessageComposer from "../message-composer/message-composer";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import MessageHelper from "../../../../entities/message/message-helper";
import useDeepCompareEffect from "use-deep-compare-effect";
import "./chat-window.scss";
import { EventEmitter } from "../../../../shared/utils/event-emitter";
import Events from "../../../../const/events";

const ChatWindow = () => {
  const selectedThread = useSelector(
    (state) => state?.threadStore?.selectedThread || null
  );
  const isLoaderActive = useSelector(
    (state) => state?.messageStore?.isLoaderActive || false
  );
  const messages = useSelector((state) => state?.messageStore?.messages || []);
  const pagination = useSelector(
    (state) => state?.messageStore?.pagination || {}
  );

  const chatBodyRef = useRef(null); // Ref for the chat body
  const pollingIntervalRef = useRef(null); // Ref for the polling interval

  // Determine if there are more messages to load
  const hasMoreMessages = pagination.page < pagination.totalPages;

  // Load older messages
  const loadOlderMessages = () => {
    if (hasMoreMessages) {
      // Save current scroll position
      const currentScrollHeight = chatBodyRef.current.scrollHeight;

      MessageHelper.loadNextPage().then(() => {
        // Maintain scroll position after loading more messages
        chatBodyRef.current.scrollTop =
          chatBodyRef.current.scrollHeight - currentScrollHeight;
      });
    }
  };

  // Scroll to the bottom
  const _scrollToBottom = () => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  };

  // Polling new messages every 5 seconds
  useDeepCompareEffect(() => {
    if (selectedThread && selectedThread._id) {
      _scrollToBottom();
      const checkForNewMessages = () => {
        MessageHelper.checkNewMessages(selectedThread._id);
      };

      // Start polling every 5 seconds
      pollingIntervalRef.current = setInterval(checkForNewMessages, 5000);

      // Clear the interval on unmount or when thread changes
      return () => clearInterval(pollingIntervalRef.current);
    }
  }, [selectedThread || {}]);

  let contactName = "";
  if (selectedThread) {
    if (selectedThread.title?.length) {
      contactName = selectedThread.title;
    } else if (selectedThread._contacts?.length) {
      contactName =
        selectedThread._contacts[0]?.name || selectedThread._contacts[0]?.phone;
    }
  }

  const _showQRPopup = () => {
    EventEmitter.dispatch(Events.SHOW_QR_CODE_POPUP, {
      id: selectedThread._contacts[0]?._id,
      title: contactName,
    });
  };

  if (!selectedThread) {
    return (
      <div className="chat-window">
        <div className="chat-window-inner">
          <div className="chat-window-center">
            <div className="chat-window-center-inner">
              <div className="chat-window-center-inner-content">
                <div className="chat-window-center-inner-content-inner">
                  <img
                    src="assets/images/start-chat.png"
                    alt="chat"
                    className="img-fluid"
                  />
                  <h4 className="mt-3">Select a chat to start messaging</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const getInitials = () => {
    if (selectedThread?.type !== "room") {
      return [];
    }
    const input = `${selectedThread?._contacts[0]?.name} & ${selectedThread?._contacts[1]?.name}`;
    if (typeof input !== "string" || !input.trim()) {
      return ["U1", "U2"];
    }

    // Split the string using '&' only
    let names = input.split(/\s*&\s*/).map((name) => name.trim());

    // Extract initials
    let initials = names.map((name) => {
      let parts = name.split(/\s+/).filter(Boolean); // Split by space and remove empty parts
      if (parts.length === 1) return parts[0][0].toUpperCase(); // Single name case
      return parts.map((part) => part[0].toUpperCase()).join("");
    });

    return initials.length >= 2 ? initials : ["U1", "U2"];
  };
  const roomInitials = getInitials();

  return (
    <>
      <div className="chat-header">
        <a href="#" className="media">
          <div className="media-left">
            <div className="avatar-box thumb-md align-self-center">
              {selectedThread?.type === "room" ? (
                // <img
                //   src={"/assets/images/group_duo.png"}
                //   alt="user"
                //   className="rounded-circle thumb-md"
                // />
                <div className="roomInitials">
                  <div className="initial one">{roomInitials[0]}</div>
                  <div className="initial two">{roomInitials[1]}</div>
                </div>
              ) : (
                <span
                  className="thumb-md justify-content-center d-flex align-items-center bg-soft-primary rounded-circle"
                  onClick={_showQRPopup}
                >
                  {contactName[0]}
                </span>
              )}
            </div>
          </div>
          <div className="media-body">
            <div>
              <h6 className="m-0">
                {selectedThread?.type === "room"
                  ? `${selectedThread?._contacts[0]?.name} & ${selectedThread?._contacts[1]?.name}`
                  : contactName}
              </h6>
              <small>
                {selectedThread?.type === "room"
                  ? "Room"
                  : selectedThread?._contacts[0]?.phone}
              </small>
            </div>
          </div>
        </a>
      </div>

      <div className="chat-body">
        <div
          className="chat-detail"
          id="scrollableChatDiv"
          ref={chatBodyRef}
          style={{
            // height: "calc(100vh - 60px - 80px - 72px - 70px)",
            height: "calc(100vh - 80px - 72px - 70px)",
            overflowY: "scroll",
            display: "flex",
            flexDirection: "column-reverse",
            paddingBottom: "0",
            paddingTop: "32px",
          }}
        >
          {/* <div className="middleNotification">
            <span>
              We have invited <b>Wojciech Woźnicki</b> to join your conversation. Please wait for them to accept.
              <span className="timestamp">4:30 pm</span>
            </span>
          </div>
          <div className="middleNotification">
            <span className="success">
              <b>John Doe</b> joined the room with <b>Tom</b>
              <span className="timestamp">4:30 pm</span>
            </span>
          </div> */}

          <InfiniteScroll
            dataLength={messages.length} // Total messages
            next={loadOlderMessages} // Function to call to load more messages
            hasMore={hasMoreMessages} // Boolean to indicate if there are more messages to load
            style={{
              display: "flex",
              flexDirection: "column-reverse",
              overflow: "visible",
            }}
            scrollableTarget="scrollableChatDiv"
            inverse={true} // Scroll upwards to load older messages
          >
            {messages.map((message, messageIndex) => (
              <ChatMessage
                message={message}
                key={message._id}
                selectedThread={selectedThread}
                direction={
                  selectedThread?.type === "room"
                    ? "incoming"
                    : message.direction
                }
                previousMessage={messages[messageIndex + 1]}
              />
            ))}
            {isLoaderActive && (
              <div className="loaderWrapper">
                <img
                  src="assets/images/ring-loader.svg"
                  alt="loader"
                  className="loader"
                />
              </div>
            )}
          </InfiniteScroll>
        </div>
      </div>
      {selectedThread?.type !== "room" && (
        <MessageComposer
          onSendMessage={_scrollToBottom}
          canSend={messages.length > 0}
          selectedThread={selectedThread}
        />
      )}
    </>
  );
};

export default ChatWindow;
