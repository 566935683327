// http-client.js
import axios from "axios";
import AuthHelper from "../../features/authentication/helpers/auth-helper/auth-helper";

const BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:5000";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Middleware to add tokens to requests
axiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = AuthHelper.getAccessToken(); // Retrieve access token from auth helper
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`; // Attach the access token
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Middleware to handle token refresh on 401 response
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const newAccessToken = await AuthHelper.refreshToken(); // Refresh the token
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${newAccessToken}`; // Update token in headers
        return axiosInstance(originalRequest); // Retry the original request
      } catch (tokenError) {
        return Promise.reject(tokenError); // Handle token refresh error
      }
    }

    return Promise.reject(error);
  }
);

// Export a public client that does not require a token
export const PublicHttpClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Export the axios instance as a ProtectedHttpClient for endpoints that require a token
export const ProtectedHttpClient = axiosInstance;
