import React, { useEffect, useState } from "react";
import "./dashboard-layout.scss";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import AuthHelper from "../../../features/authentication/helpers/auth-helper/auth-helper";
import QRViewer from "../qr-viewer/qr-viewer";
import BulkRoomCreateModal from "../../../features/manage-contacts/pages/manage-contacts-page/components/bulk-room-create/bulk-room-create";
import OutsideClickHandler from "react-outside-click-handler";
import { Tooltip } from "react-tooltip";

const DashboardLayout = ({ children }) => {
  const { userInfo } = useSelector((state) => state.userStore);
  const [canManageUsers, setCanManageUsers] = React.useState(false);

  const location = useLocation();

  const _logout = (e) => {
    e.preventDefault();
    AuthHelper.logout();
  };

  useEffect(() => {
    if (userInfo?.role === "admin") {
      setCanManageUsers(true);
    }
  }, [userInfo]);

  const [profileDropdownShow, setProfileDropdownShow] = useState(false);

  return (
    <div className="dashboard-layout">
      {/* Sidebar */}
      <div className="left-sidebar">
        {/* LOGO */}
        <div className="brand">
          <a href="index.html" className="logo">
            <span>
              <img
                src="assets/images/small_icon.png"
                alt="logo-small"
                className="logo-sm"
              />
            </span>
          </a>
        </div>
        <div className="menu-content h-100" data-simplebar>
          <div className="menu-body navbar-vertical">
            <div
              className="collapse navbar-collapse tab-content"
              id="sidebarCollapse"
            >
              <ul
                className="navbar-nav tab-pane active"
                id="Main"
                role="tabpanel"
              >
                <Link
                  className={`nav-link ${
                    location.pathname === "/whatsapp-chats" ? "active" : ""
                  }`}
                  to="/whatsapp-chats"
                  title="Chats"
                >
                  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M0 0h48v48H0z" fill="none"></path> <g id="Shopicon"> <path d="M4,44l8-8h20c2.2,0,4-1.8,4-4v-4l8,8V8c0-2.2-1.8-4-4-4H16c-2.2,0-4,1.8-4,4v4H8c-2.2,0-4,1.8-4,4V44z M16,8h24v18.343 L37.657,24H16V8z M8,16h4v8c0,2.2,1.8,4,4,4h16v4H10.343L8,34.343V16z"></path> </g> </g></svg>
                  <span>Chats</span>
                </Link>
                <Link
                  className={`nav-link ${
                    location.pathname === "/settings" ? "active" : ""
                  }`}
                  to="/settings"
                  title="Features"
                >
                  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4.9996 7V11M9.9996 2V6M17.9996 16V20M2.9996 9H6.9996M7.9996 4H11.9996M15.9996 18H19.9996M13.9996 7L16.828 9.82843M19.5162 3.74612L20.0819 4.3118C20.4779 4.70782 20.6759 4.90583 20.7501 5.13416C20.8154 5.335 20.8154 5.55135 20.7501 5.75219C20.6759 5.98052 20.4779 6.17853 20.0819 6.57454L6.52508 20.1314C6.12906 20.5274 5.93105 20.7254 5.70272 20.7996C5.50188 20.8649 5.28553 20.8649 5.08469 20.7996C4.85636 20.7254 4.65835 20.5274 4.26234 20.1314L3.69665 19.5657C3.30063 19.1697 3.10262 18.9717 3.02844 18.7433C2.96318 18.5425 2.96318 18.3262 3.02844 18.1253C3.10262 17.897 3.30063 17.699 3.69665 17.303L17.2535 3.74612C17.6495 3.3501 17.8475 3.15209 18.0758 3.0779C18.2767 3.01265 18.493 3.01265 18.6939 3.0779C18.9222 3.15209 19.1202 3.3501 19.5162 3.74612Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span>Settings</span>
                </Link>
                {canManageUsers && (
                  <Link
                    className={`nav-link ${
                      location.pathname === "/manage-users" ? "active" : ""
                    }`}
                    to="/manage-users"
                    title="User management"
                  >
                    <svg fill="#000000" viewBox="0 0 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg" stroke="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M168,100a60,60,0,1,1-60-60A60,60,0,0,1,168,100Z" opacity="0"></path> <path d="M144.14264,157.55817a68,68,0,1,0-72.28528,0A119.88963,119.88963,0,0,0,16.083,194.85156a8.00012,8.00012,0,0,0,12.25,10.293,104.007,104.007,0,0,1,159.33447,0,8.00012,8.00012,0,0,0,12.25-10.293A119.89043,119.89043,0,0,0,144.14264,157.55817ZM56,100a52,52,0,1,1,52,52A52.059,52.059,0,0,1,56,100Zm192.24854,43.07178-4.6568-2.68866a23.73294,23.73294,0,0,0,0-8.76624l4.6568-2.68866a8,8,0,1,0-8-13.85644l-4.66816,2.69519A23.99995,23.99995,0,0,0,228,113.3761V108a8,8,0,0,0-16,0v5.3761a23.99995,23.99995,0,0,0-7.58038,4.39087l-4.66816-2.69519a8,8,0,1,0-8,13.85644l4.6568,2.68866a23.73294,23.73294,0,0,0,0,8.76624l-4.6568,2.68866a8,8,0,1,0,8,13.85644l4.66816-2.69519A23.99995,23.99995,0,0,0,212,158.6239V164a8,8,0,0,0,16,0v-5.3761a23.99995,23.99995,0,0,0,7.58038-4.39087l4.66816,2.69519a8,8,0,0,0,8-13.85644ZM212,136a8,8,0,1,1,8,8A8.00917,8.00917,0,0,1,212,136Z"></path> </g></svg>
                    <span>User list</span>
                  </Link>
                )}
                <Link
                  className={`nav-link ${
                    location.pathname === "/manage-contacts" ? "active" : ""
                  }`}
                  to="/manage-contacts"
                  title="Contact list"
                >
                  <svg fill="#000000" viewBox="0 0 16 16" id="contacts-16px" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path id="Path_131" data-name="Path 131" d="M33,6a2,2,0,1,0-2,2A2,2,0,0,0,33,6ZM31,7a1,1,0,1,1,1-1A1,1,0,0,1,31,7Zm7.5-1H38V3.5A3.5,3.5,0,0,0,34.5,0h-10a.5.5,0,0,0-.5.5V2.045h-.5a.5.5,0,0,0,0,1H24V6h-.5a.5.5,0,0,0,0,1H24v3h-.5a.5.5,0,0,0,0,1H24v3h-.5a.5.5,0,0,0,0,1H24v.5a.5.5,0,0,0,.5.5h10A3.5,3.5,0,0,0,38,12.5V10h.5a.5.5,0,0,0,.5-.5v-3A.5.5,0,0,0,38.5,6ZM38,9H37a1,1,0,0,1,0-2h1Zm-1,3.5A2.5,2.5,0,0,1,34.5,15h-9a.5.5,0,0,0,0-1H25V11h.5a.5.5,0,0,0,0-1H25V7h.5a.5.5,0,0,0,0-1H25V3.045h.545a.5.5,0,0,0,0-1H25V1h9.5A2.5,2.5,0,0,1,37,3.5V6a2,2,0,0,0,0,4ZM31.5,8h-1A3.5,3.5,0,0,0,27,11.5,1.5,1.5,0,0,0,28.5,13h5A1.5,1.5,0,0,0,35,11.5,3.5,3.5,0,0,0,31.5,8Zm2,4h-5a.5.5,0,0,1-.5-.5A2.5,2.5,0,0,1,30.5,9h1A2.5,2.5,0,0,1,34,11.5.5.5,0,0,1,33.5,12Z" transform="translate(-23)"></path> </g></svg>
                  <span>Contact list</span>
                </Link>
              </ul>
            </div>
          </div>
        </div>
        <div className="menuSidebarBottom">
          <div className="userProfile" onClick={(e) => setProfileDropdownShow(true)}>
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M18 18.7023C18 15.6706 14.5 15 12 15C9.5 15 6 15.6706 6 18.7023M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM15 9C15 10.6569 13.6569 12 12 12C10.3431 12 9 10.6569 9 9C9 7.34315 10.3431 6 12 6C13.6569 6 15 7.34315 15 9Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
          </div>
          
          {
            profileDropdownShow ?
            <OutsideClickHandler onOutsideClick={(e) => setProfileDropdownShow(false)}>
              <div className="profileDropdown">
                <div className="logOut profileDropdownItem" onClick={_logout}>
                  <i className="fa fa-power-off"></i> <span>Logout</span>
                </div>
              </div>
            </OutsideClickHandler> : ""
          }
        </div>
      </div>

      {/* Top Bar */}
      {/* <div className="topbar">
        <nav className="navbar-custom" id="navbar-custom">
          <ul className="list-unstyled topbar-nav float-end mb-0">
            <li className="dropdown">
              <a
                className="nav-link dropdown-toggle nav-user"
                data-bs-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <div className="d-flex align-items-center">
                  <div>
                    <small className="d-none d-md-block font-11">
                      Team RELAi
                    </small>
                    <span className="d-none d-md-block fw-semibold font-12">
                      {userInfo?.email} <i className="mdi mdi-chevron-down" />
                    </span>
                  </div>
                </div>
              </a>
              <div className="dropdown-menu dropdown-menu-end">
                <div className="dropdown-divider mb-0" />
                <a className="dropdown-item" href="#" onClick={_logout}>
                  <i className="ti ti-power font-16 me-1 align-text-bottom" />{" "}
                  Logout
                </a>
              </div>
            </li>
          </ul>
          <ul className="list-unstyled topbar-nav mb-0">
            <li>
              <h4>Welcome to your RelAi Dashboard</h4>
            </li>
          </ul>
        </nav>
      </div> */}

      {/* Page Content */}
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container h-100">{children}</div>
        </div>
      </div>
      <QRViewer />
    </div>
  );
};

export default DashboardLayout;
