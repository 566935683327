import React, { useState, useEffect } from "react";
import "./manage-contacts-page.scss";
import DataTable from "react-data-table-component";
import ContactHelper from "../../../../entities/contact/contact-helper";
import { useSelector } from "react-redux";
import AddContactModal from "../../components/add-contact-modal/add-contact-modal";

const ManageContactsPage = () => {
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { contacts } = useSelector((state) => state.contactsStore);

  // Function to update and sort contacts
  const updateContacts = (contactsList) => {
    setFilteredContacts(
      contactsList
        .map((contact) => ({
          id: contact._id,
          name: contact.name || "Unknown",
          phone: contact.phone || "N/A",
          dateAdded: new Date(contact.createdAt).toLocaleDateString(),
          createdAt: new Date(contact.createdAt),
        }))
        .sort((a, b) => b.createdAt - a.createdAt) // Sort by latest first
    );
  };

  useEffect(() => {
    if (contacts) {
      updateContacts(contacts);
    }
  }, [contacts]);

  const handleSearch = (e) => {
    const text = e.target.value.toLowerCase();
    setSearchText(text);

    updateContacts(
      contacts.filter(
        (contact) =>
          (contact.name && contact.name.toLowerCase().includes(text)) ||
          (contact.phone && contact.phone.toLowerCase().includes(text))
      )
    );
  };

  useEffect(() => {
    ContactHelper.fetchAllContacts(); // Initial fetch
  }, []);

  const handleContactAdded = () => {
    ContactHelper.fetchAllContacts(); // Refresh contacts after adding a new one
  };

  return (
    <div className="manage-contacts-page">
      <h1 class="m-0 mb-4">Contact lists</h1>

      <div className="chat-search d-flex align-items-center gap-2 mb-3">
        <button
          type="button"
          className="btn btn-success btn-sm"
          onClick={() => setShowModal(true)}
        >
          <i className="mdi mdi-plus me-2"></i>
          Add Contact
        </button>
        <div className="form-group flex-grow-1">
          <div className="input-group">
            <input
              type="text"
              className="form-control w-50"
              placeholder="Search contacts..."
              value={searchText}
              onChange={handleSearch}
            />
          </div>
        </div>
      </div>

      <DataTable
        columns={[
          { name: "Name", selector: (row) => row.name, sortable: true },
          { name: "Phone", selector: (row) => row.phone, sortable: true },
          {
            name: "Date Added",
            selector: (row) => row.dateAdded,
            sortable: true,
          },
        ]}
        data={filteredContacts}
        pagination
      />

      <AddContactModal
        show={showModal}
        onHide={() => setShowModal(false)}
        onContactAdded={handleContactAdded} // Refresh contacts when a new one is added
      />
    </div>
  );
};

export default ManageContactsPage;
